import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { ModalView } from '@components/ui/Modal/Modal';
import Text from '@components/ui/Text/Text';

import style from './LoadingView.module.scss';

const LoadingView: FC<ModalView> = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className={style.root}>
      <Text className="mb-xl text-center" variant="heading-3" weight="semibold">
        <FontAwesomeIcon icon={faSpinnerThird} size="2x" spin title={t('loading.description')} />
      </Text>
      <Text variant="text-4" className="text-center">
        {t('loading.description')}
      </Text>
    </div>
  );
};

export default LoadingView;
